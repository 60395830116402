<template>
  <component :is="tag" class="domusvi-team">
    <div class="section-inner section-contained">
      <h2 v-if="blok.title" class="size-xl align-center">{{ blok.title }}</h2>
      <p v-if="blok.subtitle" class="subtitle size-s align-center">{{ blok.subtitle }}</p>
      <template v-if="hasItems">
        <div v-if="isCards" :class="`cards ${blok.title || blok.subtitle ? 'margin-top' : ''}`">
          <StoryblokComponent
            v-for="item in blok.items"
            :key="item._uid"
            :blok="item"
            :level="3"
            text-size="size-s"
            class="card"
            :size-hint="50"
          />
        </div>
        <div
          v-if="isCarousel"
          :class="`carousel-container carousel-full-width ${blok.title || blok.subtitle ? 'margin-top' : ''}`"
        >
          <GenericCarousel
            :items="blok.items"
            :slides-per-view="'auto'"
            :space-between="isTabletPortrait ? 10 : 18"
            class="carousel-navigation-bottom"
            :child-level="3"
            child-text-size="size-s"
            child-classes="card"
            :size-hint="50"
          />
        </div>
      </template>
    </div>
  </component>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  tag: {
    type: String,
    required: false,
    default: "div",
  },
});

const isTabletPortrait = useCustomBreakpoints("for-tablet-portrait-up");
const hasItems = computed(() => {
  return props.blok.items?.length > 0;
});

const isCards = computed(() => {
  return (
    (isTabletPortrait.value && props.blok.items?.length < 3) ||
    (!isTabletPortrait.value && props.blok.items?.length < 2)
  );
});

const isCarousel = computed(() => {
  return (
    (isTabletPortrait.value && props.blok.items?.length >= 3) ||
    (!isTabletPortrait.value && props.blok.items?.length >= 2)
  );
});
</script>

<style lang="scss" scoped>
.domusvi-team {
  :deep(.swiper-button-prev),
  :deep(.swiper-button-next) {
    &::before {
      background-color: var(--solid-01);
    }
  }

  --margin-col-span: 1;
  --cards-gap: 1.125rem;

  @include for-tablet-portrait-up {
    --cards-gap: 0.625rem;

    :deep(.carousel-navigation-bottom > .swiper-pagination) {
      padding-right: calc(100vw * var(--margin-col-span) / 20);
    }
  }

  @include for-desktop-up {
    --margin-col-span: 3;

    .section-contained {
      grid-column: calc(var(--margin-col-span) + 1) /
        calc(-1 * var(--margin-col-span) - 1);
    }
  }

  .cards {
    display: flex;
    justify-content: center;
    gap: var(--cards-gap);
  }

  :deep(.card) {
    @include for-tablet-portrait-up {
      width: calc(
        (1 - var(--margin-col-span) / 10) * min(50vw, $max-width / 2) -
          var(--cards-gap) / 2
      );
    }
  }

  :deep(.media-text-cta) {
    @include pair-7;

    @include for-tablet-landscape-up {
      gap: 2rem;
    }
  }

  .subtitle {
    margin-top: 0.25rem;

    @include for-desktop-up {
      margin-top: 0.75rem;
    }
  }

  :deep(.media-text-cta .text-wrapper) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    @include for-tablet-landscape-up {
      width: 20rem;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
